// 个案历史跟踪相关列表表头数据,relatedlistId: "aee2015962170943N4S2"
 const fieldList = [
    {
      "modify": "false",
      "isContrField": false,
      "visible": false,
      "length": "255",
      "name": "createdate",
      "id": "case1671345379820",
    //   操作时间
      "label": "label.operation.date",
      "type": "F"
    },
    {
      "modify": "false",
      "isContrField": false,
      "visible": false,
      "length": "255",
      "name": "casestatus",
      "id": "case1671345379821",
    //   状态
      "label": "label.chatter.state",
      "type": "S",
    },
    {
      "modify": "false",
      "isContrField": false,
      "visible": false,
      "length": "255",
      "name": "leixing",
      "id": "case1671345379822",
    //   类型
      "label": "label.emailtocloudcc.filed.type",
      "type": "S"
    },
    {
      "modify": "false",
      "isContrField": false,
      "visible": false,
      "length": "6",
      "name": "casetype",
      "options": [{
        "field_id": "case1671345379833",
        "codekey": "new",
        "isactive": "0",
        "isdefaultvalue": "0",
        "sortorder": "1",
        // 新建 case
        "codevalue": "c2168",
        "fieldId": "case1671345379833"
      },{
        "field_id": "case1671345379843",
        "codekey": "edit",
        "isactive": "0",
        "isdefaultvalue": "0",
        "sortorder": "1",
        // case 变更
        "codevalue": "c2167",
        "fieldId": "case1671345379843"
      },{
        "field_id": "case1671345379853",
        "codekey": "email",
        "isactive": "0",
        "isdefaultvalue": "0",
        "sortorder": "1",
        // 发邮件
        "codevalue": "label.chosesubject.sendemail",
        "fieldId": "case1671345379853"
      },{
        "field_id": "case1671345379863",
        "codekey": "sms",
        "isactive": "0",
        "isdefaultvalue": "0",
        "sortorder": "1",
        // 发短信
        "codevalue": "label.ems.sms",
        "fieldId": "case1671345379863"
      }],
      "id": "case1671345379823",
    //   操作类型
      "label": "label.monitorsys.delete.style",
      "type": "L"
    },
    {
      "modify": "false",
      "isContrField": false,
      "visible": false,
      "length": "255",
      "name": "userid",
      "id": "case1671345379824",
    //   操作人
      "label": "label.monitorsys.operator",
      "type": "X"
    },
    {
      "modify": "false",
      "isContrField": false,
      "visible": false,
      "length": "255",
      "name": "casecontent",
      "id": "case1671345379825",
    //   描述
      "label": "label.appointmentconfirmail.wizard.subtitle4",
      "type": "S"
    }
  ]
  export default fieldList
import axios from '@/config/httpConfig'

// 根据条件查找类似业务机会
export function getSimilarOpportunityGetSimilarOpportunity (data) {
  return axios.post('/similarOpportunity/getSimilarOpportunity', data)
}

// 获取类似业务机会匹配条件
export function getSimilarOpportunitygetSimilarOppConditions (data) {
  return axios.post('/similarOpportunity/getSimilarOppConditions', data)
}

// 类似业务机会保存选中设置标签
export function getSimilarOpportunitySaveBookmarksOppDetails (data) {
  return axios.post('/similarOpportunity/saveBookmarksOppDetails', data)
}

// 删除类似业务机会
export function getSimilarOpportunityDeleteBookmarksOppDetails (data) {
  return axios.post('/similarOpportunity/deleteBookmarksOppDetails', data)
}

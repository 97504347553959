<!-- 查找/查找多选弹框 -->
<template>
  <div>
    <!-- 查找条件 -->
    <span>{{$t('label.titlename')}}：</span>
    <el-input v-model="searchKeyWord" class="searchKeyWord-input">
      <!-- 无搜索条件时展示模糊搜索框 -->
    </el-input>
    <!-- 按钮 -->
    <div style="margin-bottom: 10px">
      <el-button size="mini" type="primary" @click="search">
        <!-- 搜索 -->
        {{ $t("label.searchs") }}
      </el-button>
      <el-button size="mini" type="primary" @click="clearSelect">
        <!-- 清空 -->
        {{ $t("label_emailtocloudcc_clear") }}
      </el-button>
    </div>
    <!-- 列表 -->
    <table-panel
      class="text-center"
      ref="tablePanel"
      :border="true"
      :show-lock="false"
      :table-height="tableHeight"
      :show-index="false"
      :checked="checked"
      :pict-loading="pictLoading"
      :table-attr="tableAttr"
      :page-obj="pageObj"
      :noDataTip="noDataTip"
      :use-hyperlinks="false"
      @append="append"
      @selectData="select"
    >
    </table-panel>
  </div>
</template>

<script>
import { TablePanel } from "@/components/index";
import * as CommonObjApi from "../../api";

export default {
  name: "SearchTable",
  components: { TablePanel },
  props: {
    // 查找的数据是否支持多选
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeight: 400,
      objId: this.$route.params.objId,
      tableAttr: [
        {
          "schemefieldName": "subject",
          "nameLabel": this.$i18n.t("label_emailtocloudcc_name"),
          "click": "selectData"
        },
        {
          "schemefieldName": "createuser",
          "nameLabel": this.$i18n.t("workflow.action.tasks.assignortype.created"),
        },
        {
          "schemefieldName": "createdate",
          "nameLabel": this.$i18n.t("label.trigger.createdate"),
        },
        {
          "schemefieldName": "lastmodifydate",
          "nameLabel": this.$i18n.t("label.service.miniProgram.lastDate"),
        }
      ],
      pageObj: {
        dataList: [],
      },
      pictLoading: false,
      noDataTip: false,
      page: 1, //页码默认为1
      pageSize: 20, // 每页展示数据条数
      searchKeyWord: "",
    };
  },
  created() {
    var _this = this;
    document.onkeydown = function () {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.search();
      }
    };
    this.init()
  },
  methods: {
    init() {
      this.page = 1;
      this.searchKeyWord = "";
      this.pageObj.dataList = [];
      this.getLookupData();
    },
    // 搜索
    search() {
      this.page = 1;
      this.pageObj.dataList = [];
      this.getLookupData();
    },
    // 获取查找数据
    getLookupData() {
      this.pictLoading = true;
      CommonObjApi.getPosterFormList({ searchKeyWord: this.searchKeyWord }).then((res) => {
        if (res.result) {
          // 页码为1时重新请求表头
          this.pictLoading = false
          this.pageObj.dataList = res.data.formlist;
          this.$refs.tablePanel.changeTableAttr(this.tableAttr);
          this.$refs.tablePanel.dataList = this.pageObj.dataList;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          })
        }
      });
    },
    // 选中数据
    select(row) {
      this.$emit("changeSelect", row);
    },
    // 清空选中数据
    clearSelect() {
      this.$emit("changeSelect", {});
    },
    cancel() {
      this.$emit("cancel");
    }
  },
};
</script>

<style lang="scss" scoped>
.searchKeyWord-input {
  width: 50%;
  margin-bottom: 10px;
}

::v-deep .el-input__inner {
  height: 34px;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>

<!--  -->
<template>
  <div>
    <el-dialog
      :visible.sync="sendVisible"
      top="10%"
      :title="bigTitle"
      width="65%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <div class="top">
        <div class="mainBox">
          <p class="headline">{{ $t("label.letter.body") }}：</p>
          <el-input type="textarea" v-model="template" :rows="15" disabled>
          </el-input>
          <div class="updateBox">
            <p class="updatetitle">{{ $t("label.attachment") }}：</p>
            <el-upload
              class="upload-demo"
              ref="upload"
              :limit="1"
              :action="action"
              :show-file-list="true"
              :multiple="false"
              :before-upload="beforeUpload"
              :on-change="onChange"
              :on-remove="onRemove"
              :on-exceed="onExceed"
            >
              <el-button slot="trigger" class="updateBt" size="mini">{{
                $t("label.tabpage.choosefilez")
              }}</el-button>
            </el-upload>
            <p class="tips">{{ topsMessage }}</p>
          </div>
          <div class="errMessage">{{ $t("label.file.size.limit") }}</div>
          <div class="buttonBox">
            <el-button
              class="button"
              type="primary"
              size="small"
              @click="saveMail"
              >{{ $t("component.sendmail.button.send") }}</el-button
            >
            <el-button class="button" size="small" @click="cancel">{{
              $t("label.emailsync.button.cancel")
            }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as CommonObjApi from "./api.js";
import * as FormMarkObjApi from "./api.js";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    ids: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      dialogBodyHeight: `${document.body.offsetHeight - 100}px`,
      topsMessage: this.$i18n.t("label.file.noselect"),
      action: "",
      size: 10240,
      fileId: "",
      template: this.$i18n.t("user.email.template"),
      sendVisible: false,
      bigTitle: this.$i18n.t("c86"),//市场活动：编辑员工邀约
    };
  },
  created() {},
  //方法集合
  methods: {
    onExceed() {
      this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
    },
    onChange() {
      this.topsMessage = "";
    },
    onRemove() {
      this.topsMessage = this.$i18n.t("label.file.noselect");
    },
    //取消
    cancel() {
      this.sendVisible = false;
    },
    //触发保存邮箱
    saveMail() {
      this.sendMail();
    },
    //发送邮箱
    sendMail() {
      FormMarkObjApi.sendInvitationCode({
        id: this.id,
        inviteduser: this.ids.toString(),
        fileId: this.fileId,
      }).then((res) => {
        if (res.result) {
          this.$message.success(this.$i18n.t("label.apply.send.success"));
          this.fileId = "";
          this.sendVisible = false;
          this.$emit("closeSendMail");
        } else {
          this.$message.$error(
            this.$i18n.t("label.emailobject.emailsentfailed")
          );
        }
      });
    },
    // 文件上传前执行的事件
    beforeUpload(file) {
      let size = file.size / 1024;
      if (size > this.size) {
        // 文件大小超过限制
        this.$message.error(
          file.name + this.$i18n.t("vue_label_file_size_limit_tip")
        );
        return false;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("groupid", "");
      formData.append("libid", "");
      formData.append("parentid", "");
      formData.append("isFromEmail", "1");
      CommonObjApi.upload(formData)
        .then((res) => {
          if (res.result) {
            this.fileId = res.data.fileinfoid;
          } else {
            this.$message.warning(this.$i18n.t("label.file.upload.fail"));
          }
        })
        .catch(() => {
          return false;
        });
      
      // this.sendMail();
    },
    handleExceed() {
      // 文件个数超出预设，如果需要替换文件，请先删除已上传的文件
      this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-textarea__inner {
  resize: none;
}

.buttonBox {
  float: right;
}
.button {
  text-align: center;
  width: 80px;
  margin-top: 20px;
  display: inline-block;
}
.errMessage {
  font-family: SourceHanSansCN-Regular;
  font-size: 12px;
  color: #c12217;
  letter-spacing: 0;
  line-height: 30px;
}
.updateBox {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  position: relative;
  .updatetitle {
    margin-right: 5px;
    float: left;
    height: 21px;
    font-family: SourceHanSansCN-Bold;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
    line-height: 30px;
  }
  .updateBt {
    margin-right: 5px;
    float: left;
  }
  .tips {
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #888888;
    letter-spacing: 0;
    line-height: 30px;
  }
}
.el-textarea__inner {
  // 然后找到对应的类名，在这里将拉伸去掉即可
  resize: none !important;
}
.headline {
  text-align: left;
  letter-spacing: 0;
  line-height: 30px;
}
.top {
  width: 100%;
  font-family: SourceHanSansCN-Bold;
  font-size: 16px;
  color: #080707;
}
.mainBox {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin: 0 auto;
}
</style>
<template>
  <!-- 开发人员：王雪茹 -->
  <!-- 报价单 -->
  <div class="pop_box">
    <el-dialog
      :title="$t('lable.opppdt.selPbk.title')"
      :visible="manualVisible"
      width="28%"
      :before-close="closeManualDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      class="manual"
    >
      <el-form
        :model="manualForm"
        :rules="manualRules"
        ref="manualForm"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
      >
        <div class="prompt">
          <!-- 您只能从单个价格手册添加产品，更改已选取价格手册将从该业务机会中删除全部现有的产品。 -->
          {{ $t("lable.opppdt.selPbk.description") }}
        </div>
        <el-form-item :label="$t('lable.opppdt.selPbk.title')" prop="region">
          <el-select v-model="manualForm.region">
            <el-option
              v-for="val in pricebooks"
              :key="val.id"
              :label="val.name"
              :value="val.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="manualClose('manualForm')" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="manualNext('manualForm')"
          size="small"
        >
          <!-- 下一步 -->
          {{ $t("UG_Forcast_09") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加产品 -->
    <el-dialog
      :title="$t('vue_label_opportunity_product_addproduct')"
      :visible="addmanualVisible"
      width="50%"
      :before-close="closeAddmanualDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <!-- 当前价格手册： -->
      <div class="pop_tit">
        {{ $t("vue_label_pricebook_book") }}：{{ defaultManual }}
      </div>
      <div class="btn_box">
        <span @click="closeFilter">{{ filtername }}</span>
        <!-- 进行筛选 -->
        <span @click="filterBtn">{{ $t("label.mobile.phone.screen") }}</span>
        <!-- @click="openFilter" -->
        <!-- 清除条件 -->
        <span @click="cleanFilter">{{ $t("label.delete.condition") }}</span>
      </div>
      <el-form
        :model="addmanualForm"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
        v-show="filterFlag"
      >
        <!-- 筛选器 -->
        <div
          class="screen_s"
          v-for="(ev, index) in addmanualForm.list"
          :key="index"
        >
          <el-form-item>
            <span style="width: 20px">{{ index + 1 }}</span>
            <el-select
              v-model="ev.fieldId"
              :placeholder="$t('vue_label_commonobjects_detail_field')"
              class="select_one"
              filterable
              @change="selectChange($event, index)"
            >
              <el-option
                v-for="item in optionst_one"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 运算符 -->
            <el-select
              v-model="ev.op"
              :placeholder="$t('label.operator')"
              class="select_two"
              filterable
            >
              <el-option
                v-for="item in ev.optionst_two"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <EditableCell
              ref="editableCell"
              :inputType="ev.inputType"
              :options="selecArr"
              :value="ev.val"
              :max="1000000000000000000"
              @remoteSearch="remoteSearchs(ev, index)"
              style="width: 38%; position: relative"
            ></EditableCell>
            <div style="width: 100px">
              <span
                class="filter_btn"
                @click="dels(index)"
                v-if="addmanualForm.list.length !== 1"
                >-</span
              >
              <span
                class="filter_btn"
                @click="adds"
                v-if="
                  addmanualForm.list.length - 1 === index &&
                  addmanualForm.list.length <= 4
                "
                >+</span
              >
            </div>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        ref="addmanualData"
        :data="addmanualData"
        v-loadmore="loadMore"
        tooltip-effect="dark"
        class="add_table"
        style="width: 100%; margin-top: 28px"
        :header-cell-style="{
          background: '#DDDBDA',
          color: '#333333',
          fontWeight: 400,
        }"
        height="200px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- 产品名称 -->
        <el-table-column prop="name" :label="$t('label.product.name')">
          <template slot-scope="scope">
            <span style="color: #006dcc; cursor: pointer">{{
              scope.row.name
            }}</span>
          </template>
        </el-table-column>
        <!-- 创建人 -->
        <el-table-column :label="$t('label.createby')" prop="createbyidccname">
          <template slot-scope="scope">{{
            scope.row.createbyidccname
          }}</template>
        </el-table-column>
        <!-- 创建时间 -->
        <el-table-column :label="$t('label.createdate')" prop="createdate">
          <template slot-scope="scope">{{ scope.row.createdate }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addmanualClose" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button type="primary" @click="addmanualNext" size="small">
          <!-- 下一步 -->
          {{ $t("UG_Forcast_09") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑现有产品 -->
    <el-dialog
      :title="$t('lable.opppdt.editPdt.title')"
      :visible="productsVisible"
      width="75%"
      :before-close="closeProductsDialog"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      class="products"
    >
      <el-form
        :model="productsForm"
        ref="productsForm"
        :rules="productsFormRules"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
      >
        <el-table
          :data="productsForm.opppdts"
          :key="tableKey"
          :header-cell-class-name="starAdd"
          style="width: 100%"
          :header-cell-style="{
            background: '#DDDBDA',
            color: '#333333',
            fontWeight: 400,
          }"
        >
          <!-- 产品 -->
          <el-table-column
            :label="$t('label.product')"
            min-width="100"
            prop="productname"
          ></el-table-column>
          <!-- 数量 -->
          <el-table-column
            :label="$t('label.forecast.settings.sl')"
            min-width="80"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'opppdts.' + scope.$index + '.quantity'"
                :rules="productsFormRules.quantity"
              >
                <el-input
                  v-model="scope.row.quantity"
                  size="small"
                  @input="
                    opppdtsNumbereCheck(scope, scope.row.quantity, 'quantity')
                  "
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 销售价格 -->
          <el-table-column :label="$t('label.quote.xsjg')" min-width="80">
            <template slot-scope="scope">
              <el-form-item
                :prop="'opppdts.' + scope.$index + '.unitprice'"
                :rules="productsFormRules.unitprice"
              >
                <el-input
                  v-model="scope.row.unitprice"
                  @input="
                    unitpriceNumbereCheck(
                      scope,
                      scope.row.unitprice,
                      'unitprice'
                    )
                  "
                  size="small"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 日期 -->
          <el-table-column :label="$t('date')" min-width="160">
            <template slot-scope="scope">
              <el-form-item>
                <el-date-picker
                  v-model="scope.row.date"
                  value-format="yyyy-MM-dd"
                  type="date"
                  size="small"
                >
                </el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 行描述 -->
          <el-table-column
            :label="$t('lable.opppdt.addpdt.table.description')"
            min-width="160"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.description" size="small"></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <span
                @click="delProducts(scope.row.id)"
                size="mini"
                style="color: red; cursor: pointer"
                ><!-- 删除 -->
                {{ $t("button_partner_delete") }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="productsClose('productsForm')" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="productsSumit('productsForm')"
          size="small"
          v-preventReClick
          ><!-- 确定 -->
          {{ $t("label.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :modal-append-to-body="false"
      :before-close="beforeClose"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :relevantObjid="relevantObjId"
        :relevantPrefix="relevantPrefix"
        @changeSelect="changeSelect"
      />
    </el-dialog>
  </div>
</template>
<script>
import {
  GetSavePricebook,
  GetSaveProducts,
  GetSelectAddProducts,
  GetQuotePdtPricebooks,
  GetSearchProducts,
  getAddQuotePdtInfo,
  GetViewGetSelectValue,
  GetCurrencyInfo,
  getRecordType,
  getPermissionById,
  del,
} from "./api";
import filterValueInputType from "@/config/enumCode/inputType.js";
import operator from "@/config/enumCode/operator.js";
import EditableCell from "@/components/TablePanel/EditableCell.vue";

export default {
  name: "popUpTeam",
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: { EditableCell },
  props: {
    //价格手册
    manualVisible: {
      type: Boolean,
      default: false,
    },
    //添加产品
    addmanualVisible: {
      type: Boolean,
      default: false,
    },
    //编辑现有产品
    productsVisible: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
    },
  },
  data() {
    return {
      //价格手册
      manualForm: {
        region: [],
      },
      manualRules: {
        region: [
          {
            required: true,
            message: this.$i18n.t("message.oppdt.selectpricebook"),
            trigger: "change",
          },
        ],
      },
      pricebooks: [],
      defaultManual: "",
      //添加产品
      tableKey: 0,
      addmanualForm: {
        list: [
          {
            fieldId: "",
            op: "",
            val: {
              value: "",
            },
            type: 1,
            id: 1,
            inputType: "input",
            optionst_two: [],
          },
        ],
      },
      initData: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        type: 1,
        inputType: "input",
        optionst_two: [],
      },
      optionst_one: [],
      dataId: 1,
      filterFlag: true,
      selecArr: [],
      SelectChangeArr: [], //选中的数组
      filtername: this.$i18n.t("vue_label_commonobjects_detail_filter"), //收起筛选器
      //编辑现有产品
      productsForm: {
        opppdts: [],
      },
      fieldId: "",
      condition: [],
      singlePageSize: 3,
      singlePage: 1,
      noDataTip: false,
      addmanualData: [],
      productsFormRules: {
        //数量不能为空
        quantity: [
          {
            required: true,
            message: this.$i18n.t("label.numberscannotnull"),
            trigger: "blur",
          },
        ],
        unitprice: [
          {
            required: true,
            message: this.$i18n.t("vue_label_commonobjects_detail_empty"),
            trigger: "blur",
          },
        ],
      },
      manual: "",
      rowIndex: 0,
      showSearchTable: false,
      relevantObjId: "",
      relevantPrefix: "",
    };
  },
  mounted() {},
  methods: {
    unitpriceNumbereCheck(scope, val) {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      if (scope.$index) {
        this.productsForm.opppdts[scope.$index].unitprice = val;
      } else {
        this.productsForm.opppdts[0].unitprice = val;
      }
    },
    opppdtsNumbereCheck(scope, val) {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      if (scope.$index) {
        this.productsForm.opppdts[scope.$index].quantity = val;
      } else {
        this.productsForm.opppdts[0].quantity = val;
      }
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    remoteSearchs(item, num) {
      this.rowIndex = num;
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    //管理小组 用户信息
    changeSelect(n) {
      if (n.length !== 0 && n.data.id) {
        this.addmanualForm.list.forEach((item, index) => {
          if (this.rowIndex === index) {
            if (n.length !== 0) {
              let options = {
                label: n.data.id,
                value: n.data.name,
              };
              this.$set(this.addmanualForm.list[this.rowIndex], "val", options);
            } else {
              this.$set(this.addmanualForm.list[this.rowIndex], "val", n);
            }
          }
        });
        this.showSearchTable = false;
      } else {
        this.showSearchTable = false;
      }
    },
      // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
      // 表格滚动懒加载
    append() {
      if (!this.noDataTip) {
        this.singleSearchProducts();
      }
    },
    // 滚动事件
    loadMore() {
      let that = this;
      let dom = document.getElementsByClassName("add_table")[0].children[2];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 1) {
          //等于0证明已经到底，可以请求接口
          if (!this.noDataTip) {
            that.fnThrottle(that.append, 500, 1000)();
          }
        }
      });
    },
    //价格手册
    closeManualDialog() {
      this.$emit("manualClose");
    },
    manualClose() {
      this.$emit("manualClose");
    },
    //报价单添加产品 列表
    addmanualInit() {
      let data = {
        quoteId: this.$route.params.id,
      };
      getAddQuotePdtInfo(data).then((res) => {
        if (res.data) {
          this.optionst_one = res.data.fieldList;
          this.pricebooks.forEach((val) => {
            if (val.id === res.data.pricebookId) {
              this.defaultManual = val.name;
            }
          });
        }
      });
    },
    //选择价格手册 下一步
    manualNext(formName) {
      if (this.pricebooks) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = {
              quoteId: this.$route.params.id, //报价单id
              pricebookId: this.manualForm.region,
            };
            GetSavePricebook(data).then((res) => {
              if (res.result === true) {
                this.$emit("addmanualChange");
                this.addmanualInit();
                this.singleSearchProducts();
              } else {
                this.$message({
                  showClose: true,
                  message: res.returnInfo,
                  type: "error",
                });
              }
            });
          } else {
            return false;
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: this.$i18n.t("message.oppdt.selectpricebook"),
          type: "warning",
        });
      }
    },
    singleManualInit() {
      this.singlePage = 1;
      this.cleanFilter();
      let data = {
        quoteId: this.$route.params.id,
      };
      GetQuotePdtPricebooks(data).then((res) => {
        if (res.data.pricebooks) {
          // 有价格手册打开天剑产品，反之打开选择价格手册弹窗
          if (res.data.pricebookId) {
            res.data.pricebooks.forEach((val) => {
              if (val.id === res.data.pricebookId) {
                this.defaultManual = val.name;
              }
            });
            this.manualForm.region = res.data.pricebookId;
            this.$emit("addmanualChange");
            this.addmanualInit();
            this.singleSearchProducts();
          } else {
            this.$emit("singleManualChange");
            this.manualForm.region = res.data.pricebooks[0].id;
            if (this.manual === this.$i18n.t("label.quote.choseproduct")) {
              //添加产品
              this.$message({
                showClose: true,
                message: this.$i18n.t("message.oppdt.selectpricebook"),
                type: "warning",
              });
            }
          }
          this.pricebooks = res.data.pricebooks;
        }
      });
    },
    //添加产品 (判断有没有价格手册)
    isManual() {
      this.manual = this.$i18n.t("label.quote.choseproduct");
      this.page = 1;
      this.condition = [];
      // this.manuFun()
    },
    // 获取查找数据
    singleSearchProducts() {
      this.noDataTip = false;
      let data = {
        quoteId: this.$route.params.id,
        conditonstr:
          this.condition.length > 0 ? JSON.stringify(this.condition) : "", //条件
        keywords: "", //关键字
        page: this.singlePage, // 页数
        pageSize: this.singlePageSize, // 条数
      };
      GetSearchProducts(data).then((res) => {
        if (this.singlePage === 1) {
          this.addmanualData = [];
        }
        if (res.data.dataList.length === 0 && this.singlePage === 1) {
          this.addmanualData = [];
        } else if (this.addmanualData.length === 0 || this.singlePage === 1) {
          this.addmanualData = res.data.dataList;
          this.singlePage++;
        } else {
          this.addmanualData = [...this.addmanualData, ...res.data.dataList];
          this.singlePage++;
        }
        // 是否提示无更多数据
        if (this.addmanualData.length === res.data.totalCount) {
          this.noDataTip = true;
        }
      });
    },
    //添加产品 新增
    adds() {
      this.dataId = 1;
      if (this.dataId < 5) {
        this.dataId += 1;
        this.addmanualForm.list.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
    },
    //添加产品 删除
    dels(n) {
      this.addmanualForm.list.splice(n, 1);
      this.$refs.editableCell.splice(n, 1);
    },
    //添加产品 表格多选
    handleSelectionChange(val) {
      this.SelectChangeArr = [];
      val.map((item) => {
        this.SelectChangeArr.push(item.pbkid);
      });
    },
    getRecordTypesFun() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            let obj = {
              label: item.name,
              value: item.name,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      });
    },
    //添加产品 筛选下拉
    selectChange(val, index) {
      this.optionst_one.map((item) => {
        if (val === item.id) {
          this.addmanualForm.list[index].op = "";
          this.addmanualForm.list[index].optionst_two =
            operator.TYPE[item.schemefieldType].getScreeningOpeTypes();
          this.addmanualForm.list[index].val = {
            value: "",
          };
          this.addmanualForm.list[index].inputType =
            filterValueInputType[item.schemefieldType];
          this.selecArr = [];
          if (item.schemefieldType === "ct") {
            //币种
            GetCurrencyInfo().then((res) => {
              res.data.forEach((item, ) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else if (item.schemefieldType === "R") {
            //记录类型
            this.getRecordTypesFun();
          } else {
            GetViewGetSelectValue({
              fieldId: val,
            }).then((res) => {
              res.data.forEach((item, ) => {
                (item.label = item.codekey), (item.value = item.codevalue);
              });
              this.selecArr = res.data;
            });
          }
        }
      });
    },
    //添加产品 关闭筛选器
    closeFilter() {
      if (this.filterFlag) {
        this.filtername = this.$i18n.t(
          "vue_label_commonobjects_detail_openfilter"
        ); //展开筛选器;
      } else {
        this.filtername = this.$i18n.t("vue_label_commonobjects_detail_filter"); //收起筛选器
      }
      this.filterFlag = !this.filterFlag;
    },
    //筛选列表
    filterBtn() {
      let copyList = JSON.parse(JSON.stringify(this.addmanualForm.list));

      copyList.forEach((item, i) => {
        delete copyList[i].type;
        delete copyList[i].inputType;
        delete copyList[i].id;
        delete copyList[i].optionst_two;
        if (item.seq) {
          delete copyList[i].seq;
        }
      });
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item, index) => {
          copyList[index].val = item.editValue;
        });
      } else {
        copyList.forEach((item, ) => {
          item.val = item.val.value;
        });
      }
      this.condition = copyList;
      this.singlePage = 1;
      this.singleSearchProducts();
    },
    //添加产品 清除筛选器
    cleanFilter() {
      this.addmanualForm.list = [
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
        },
      ];
    },

    //添加产品 关闭弹窗
    addmanualClose() {
      this.$emit("manualClose");
      this.$emit("addmanualClose");
      // this.addmanualVisible = false
    },
    closeAddmanualDialog() {
      this.$emit("manualClose");
      this.$emit("addmanualClose");
    },
    //添加产品 下一步
    addmanualNext() {
      if (this.SelectChangeArr.length === 0) {
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_opportunity_related_chooseproduct"),
          type: "warning",
        });
      } else {
        let data = {
          quoteId: this.$route.params.id,
          selectedIds: this.SelectChangeArr.join(","),
        };
        GetSelectAddProducts(data).then((res) => {
          this.productsForm.opppdts = [];
          this.productsForm.opppdts = res.data.productsList;
        });
        this.$emit("productsChange");
      }
    },
    //编辑现有产品  删除单条
    delProducts(id) {
      this.productsForm.opppdts.splice(id, 1);
      getPermissionById({ id: id }).then((res) => {
        if (res.data.isDelete) {
          del({ id: id, objectApi: this.DeletName }).then(() => {
            this.$message({
              showClose: true,
              message: this.$i18n.t("label.ems.delete.success"),
              type: "success",
            }); //删除成功
            this.$emit("successFun", true);
            this.$emit("manualClose");
            this.$emit("addmanualClose");
          });
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t("vue_label_notice_nopermission_delete"),
            type: "warning",
          });
          //抱歉，您无权删除该条数据，请联系系统管理员。
        }
      });
    },
    //编辑现有产品 给头部添加星星
    starAdd(obj) {
      if (obj.columnIndex === 1 || obj.columnIndex === 2) {
        return "star";
      }
    },
    //编辑现有产品 保存
    productsSumit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = [];
          this.productsForm.opppdts.map((item) => {
            let newItem = {};
            newItem.oppid = this.$route.params.id;
            newItem.pricebookitem2 = item.pricebookitem2;
            newItem.product2 = item.product2;
            newItem.unitprice = item.unitprice;
            newItem.quantity = item.quantity;
            newItem.date = item.date;
            newItem.description = item.description;
            newItem.currency = "";
            data.push(newItem);
          });
          GetSaveProducts(data).then((res) => {
            if (res.result === true) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("label.search.saveok"), //保存成功
              });
              this.$emit("successFun", true);
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: this.$i18n.t("savefail"), //"保存失败"
              });
            }
            this.$emit("manualClose");
            this.$emit("addmanualClose");
          });
        } else {
          return false;
        }
      });
      this.$emit("manualClose");
      this.$emit("addmanualClose");
    },
    //编辑现有产品 关闭
    productsClose(formName) {
      this.$refs[formName] && this.$refs[formName].resetFields();
      this.$emit("successFun", false);
      this.$emit("manualClose");
      this.$emit("addmanualClose");
    },
    closeProductsDialog() {
      this.$emit("manualClose");
      this.$emit("addmanualClose");
      this.$emit("successFun", false);
    },
  },
  watch: {
    addmanualWrapArr(nval, ) {
      //默认价格手册信息
      this.manualForm.region = nval.pricebookId;
      //添加产品   表格信息
      this.optionst_one = nval.fieldList;
    },
    productsArr(nval, ) {
      this.productsForm.opppdts = nval;
    },
  },
};
</script>
<style lang="scss" scoped>
.pop_box {
  ::v-deep .el-input--small .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-table {
    table th.star div::before {
      content: "*";
      color: red;
    }
    .el-table__body-wrapper {
      overflow-y: auto;
      max-height: 400px;
      // 提示语
      .noDataTip {
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 14px;
        background: #fff;
      }
    }
  }
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #ccc;
    padding: 20px;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #ccc;
    padding: 20px;
  }
  ::v-deep .add_table {
    ::v-deep .el-table__body-wrapper {
      height: 140px;
    }
  }
  .btn_s {
    width: 30px;
    height: 30px;
    ::v-deep span {
      font-size: 32px;
      margin-left: -8px;
      line-height: 14px;
    }
  }
  .btn_s1 {
    width: 30px;
    height: 30px;
    ::v-deep span {
      font-size: 32px;
      margin-left: -9px;
      line-height: 14px;
    }
  }
  .pop_tit {
    margin-bottom: 20px;
    font-size: 12px;
  }
  .btn_box {
    span {
      color: #006dcc;
      font-size: 12px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .screen_s > div {
    margin-bottom: 0 !important;
  }

  .screen_s ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .el-form-item {
    margin-bottom: 0;
  }

  .select_one {
    width: 180px;
    margin: 6px;
  }

  .select_two {
    width: 140px;
    margin: 6px;
  }

  .select_three {
    width: 72px;
    margin: 6px;
  }
  .filter_btn {
    font-size: 24px;
    margin: 0 8px;
    cursor: pointer;
  }
  .icon_s {
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 20px;
  }
  .products {
    ::v-deep .el-form-item__content {
      margin: 14px 0;
    }
  }
  .manual {
    ::v-deep .el-form-item__error {
      left: 78px;
    }
    ::v-deep .el-form-item__label {
      min-width: 108px;
      word-break: break-word;
      height: auto;
      padding: 0;
      margin-right: 10px;
    }
    .prompt {
      margin-bottom: 20px;
      word-break: break-word;
    }
  }
}
</style>

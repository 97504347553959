/*
 * @Author: your name
 * @Date: 2022-02-07 16:27:47
 * @LastEditTime: 2022-02-11 15:02:41
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\commonObjects\formMarketingPromotion\api.js
 */
import axios from '@/config/httpConfig'
// 保存表单营销推广
export function saveCampaignForm(data) {
    return axios.post("/campaignform/saveCampaignForm", data);
}
// 获取表单营销推广的分享信息
export function getShareInfo(data) {
    return axios.post("/campaignform/getShareInfo", data);
}
// 删除表单营销推广
export function deleteCampaignForm(data) {
    return axios.post("/campaignform/deleteCampaignForm", data);
}
// 获取视图列表
export function GetViewList(data) {
    return axios.post('/view/list/getViewList', data)
}
// 邀约码
export function sendInvitationCode(data) {
    return axios.post('/campaignform/sendInvitationCode', data)
}
// 获取表单营销推广
export function getCampaignFormInfo(data) {
    return axios.post("/campaignform/getCampaignFormInfo", data);
}

// 获取视图列表表头
export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
}

// 获取视图列表记录
export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
}

//上传文件
export function upload(data, config) {
    return axios.post('/file/upload', data, config)
}
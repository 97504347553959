<template>
  <!-- 表单营销推广 新建/编辑 -->
  <div class="form_mark_box">
    <el-dialog
      :title="$t('c11')"
      :visible.sync="dialogFormVisible"
      width="750px"
      class="edit_s"
    >
      <el-form
        :model="formMark"
        :rules="formMarkRules"
        ref="formMark"
        label-position="right"
      >
        <!-- 推广名称 -->
        <el-form-item
          :label="$t('c6')"
          :label-width="formLabelWidth"
          style="position: relative"
          prop="name"
        >
          <el-input
            v-model="formMark.name"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
        <!-- 截止日期 -->
        <el-form-item
          :label="$t('label.chatter.maturiyDate')"
          :label-width="formLabelWidth"
          prop="validitydate"
        >
          <el-date-picker
            v-model="formMark.validitydate"
            clearable
            type="date"
            :placeholder="$t('label.tabpage.selectdate')"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 实际成本 -->
        <el-form-item
          :label="$t('label.campagin.roi.sjcb')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formMark.actualcost"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
        <!-- 推广所有人 -->
        <el-form-item
          :label="$t('c7')"
          :label-width="formLabelWidth"
          prop="campaignowner"
        >
          <el-select
            v-model="formMark.campaignowner"
            :placeholder="$t('label_tabpage_pleaseSelectz')"
            clearable
            size="small"
          >
            <el-option
              v-for="item in promotionArr"
              :key="item.label"
              :label="item.value"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 选择表单 -->
        <el-form-item
          :label="$t('c8')"
          :label-width="formLabelWidth"
          prop="formid"
        >
          <el-select
            v-model="formMark.formid"
            filterable
            clearable
            class="no-suffix"
          >
            <p class="searchTipBox">
              <span
                @click="remoteFromSearch"
                style="display: inline-block; width: 100%"
              >
                <i class="el-icon-search"></i>
                  <span style="padding-left: 10px">
                    {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                  </span>
              </span>
            </p>
            <el-option
              v-for="item in optionsArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>

              <!-- 这个千万不要动，一动就费 -->
            <el-option
              v-show="false"
              value=""
            >
            </el-option>

            <p class="searchTipBox" @click="newSearchableFromField()" style="display:none;">
                <i class="el-icon-plus"></i>
                <span style="padding-left: 10px">
                  {{ $t("label.new") }}
                </span>
              </p>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('formMark')" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button type="primary" @click="saveFun('formMark')" size="small"
          ><!-- 保存 -->
          {{ $t("label.save") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showFromSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :modal-append-to-body="false"
      append-to-body
    >
      <searchFromTable
          ref="searchFromTable"
          @changeSelect="changeSelect"
        />
    </el-dialog>
    <!-- （表单营销推广）邀约码 -->
    <invitationCode
      :id="listEveryId"
      :invitationCode="invitationCode"
      @closeInvitationCodeDialog="closeInvitationCodeDialog"
      @closeSendMail="closeSendMail"
    ></invitationCode>
  </div>
</template>
<script>
import invitationCode from "@/views/commonObjects/formMarketingPromotion/invitationCode.vue";
// import searchFromTable from '@/views/commonObjects/marketingPosters/component/searchFromTable.vue'
import searchFromTable from "@/views/marketList/marketingPosters/component/searchFromTable.vue";
import * as FormMarkObjApi from "./api.js";

export default {
  components: { searchFromTable,invitationCode },
  props: {
    //记录id
    id: {
      type: String,
      default: "",
    },
    //相关列表id
    listId: {
      type: String,
      default: "",
    },
    //相关列表每条数据id
    relationFieldId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogFormVisible: false,//新建编辑弹窗开关
      formMark: {//表单字段
        name: "",
        validitydate: "",
        actualcost: "",
        campaignowner: "",
        formid: "",
      },
      formLabelWidth: "180px",//label宽度
      showFromSearchTable: false,//查找弹窗开关
      optionsArr: [],//选择表单下拉项
      invitationCode:false,
      promotionArr: [//推广所有人下拉项
        {
          value: this.$i18n.t("label.all.marketing.activities"),
          label: "campaignowner",
        },
        {
          value: this.$i18n.t("c9"),
          label: "inviteduser",
        },
      ],
      pickerOptions: {//日期
        disabledDate(time) {
          return time.getTime() <= Date.now() - 3600 * 1000 * 24; // 禁用当前日期以前的日期
        },
      },
      formMarkRules: {//必填
        name: [
          {
            required: true,
            message: this.$i18n.t("vue_label_lead_required_fields"),
            trigger: "blur",
          },
        ],
        validitydate: [
          {
            required: true,
            message: this.$i18n.t("vue_label_lead_required_fields"),
            trigger: "blur",
          },
        ],
        campaignowner: [
          {
            required: true,
            message: this.$i18n.t("vue_label_lead_required_fields"),
            trigger: "blur",
          },
        ],
        formid: [
          {
            required: true,
            message: this.$i18n.t("vue_label_lead_required_fields"),
            trigger: "blur",
          },
        ],
      },
      listEveryId:'',
    };
  },
  methods: {
    //新建
    add(){
      this.formMark = {
        name: "",
        validitydate: "",
        actualcost: "",
        campaignowner: "",
        formid: "",
      }
      this.optionsArr = []
    },
    //编辑
    edit(){
      FormMarkObjApi.getCampaignFormInfo({id:this.relationFieldId}).then((res) => {
        this.optionsArr = [{
          label: res.data.formname,
          value: res.data.formid,
        }]
        this.formMark.name = res.data.name
        this.formMark.formid = res.data.formid
        this.formMark.validitydate = res.data.validitydate
        this.formMark.actualcost = res.data.actualcost
        this.formMark.campaignowner = res.data.campaignowner
      }).catch(()=>{})
    },
    //保存
    saveFun(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            name: this.formMark.name,
            validitydate: this.formMark.validitydate,
            actualcost: this.formMark.actualcost,
            campaignowner: this.formMark.campaignowner,
            formid: this.formMark.formid,
            campaignid: this.id,
            inviteduser: "",
            id:this.relationFieldId,
          };
          FormMarkObjApi.saveCampaignForm(data)
            .then((res) => {
              if (this.formMark.campaignowner === "inviteduser") {
                this.listEveryId = res.data.id
                this.$nextTick(()=>{
                  this.invitationCode = true
                })
              }else{
                this.$emit('close')
              }
            })
            .catch(() => {});
        } else {
          
          return false;
        }
      });
      this.dialogFormVisible = false
    },
    //取消
    resetForm() {
      this.dialogFormVisible = false;
    },
    ewSearchableFromField() {
      this.$router.push({
        path: '/marketForm/selectTemplate'
      })
    },
    // 查找/查找多选
    remoteFromSearch() {
      this.showFromSearchTable = true
      this.$nextTick(() => {
        this.$refs.searchFromTable.page = 1;
        this.$refs.searchFromTable.pageObj.dataList = [];
        this.$refs.searchFromTable.init();
      });
    },
    //搜索选中的数据
    changeSelect(row) {
      this.showFromSearchTable = false
      if (row.data) {
        this.optionsArr.push({
          label: row.data.subject,
          value: row.data.id,
        })
        this.optionsArr = this.norepeat(this.optionsArr)
        this.formMark.formid = row.data.id.toString()
      } else {
        this.optionsArr = []
        this.formMark.formid = ''
      }
    },
    //去重
    norepeat(arr) {
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].value == arr[j].value) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //关闭邀约码弹窗
    closeInvitationCodeDialog(){
      this.invitationCode = false
    },
    //刷新详情页
    closeSendMail(){
      this.$emit('close')
    },
  },
};
</script>
<style lang="scss" scoped>
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
  &:hover {
    background: #f5f7fa;
  }
}
.form_mark_box {
  .el-form {
    
    width: 600px;
    .el-select {
      width: 100%;
    }
    .remoteBtn {
      cursor: pointer;
      position: absolute;
      right: 9px;
      top: 1px;
      color: #c0c4cc;
      z-index: 100;
    }
    // 查找下拉框不显示下拉图标
    ::v-deep .no-suffix {
      input {
        height: 32px !important;
        line-height: 32px !important;
      }
      .el-input__suffix {
        right: 25px;
        .el-icon-arrow-up {
          display: none;
        }
      }
    }
    ::v-deep .el-date-editor {
      width: 100%;
      input {
        height: 32px !important;
        line-height: 32px !important;
      }
    }
  }
}
</style>
      
<!-- 二维码弹框 -->
<template>
  <el-dialog
    :visible.sync="qrcodeFlg"
    :title="$t('label.qrcode')"
    top="15%"
    width="55%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div style="min-width: 750px; float: left; width: 100%">
      <div class="wrapper">
        <h2
          style="
            font-size: 18px;
            color: #555;
            margin: 0px;
            padding-bottom: 15px;
          "
        >
        <!-- 市场活动表单营销推广二维码 -->
          {{ $t("c87") }}
        </h2>
        <div class="content">
          {{ $t("label.campagin.roi.name") }}：<br />
          <p>{{ bigTitle }}</p>
          <!-- 表单营销推广 -->
          {{ $t("c11") }}：<br />
          <p>{{ qrCodeName }}</p>
          {{ $t("label.url") }}:<br />
          <p>
            <a :href="url" target="_blank">{{ url }}</a
            ><br />
          </p>
          <br />
          <div style="margin-top: -10px">
            {{ $t("label.campaign.channel.download") }} :
          </div>
          <p class="download">
            <a :href="download1000">{{
              $t("label.campaign.channel.download.size1")
            }}</a>
          </p>
          <p class="download">
            <a :href="download500">{{
              $t("label.campaign.channel.download.size2")
            }}</a>
          </p>
          <p class="download">
            <a :href="download300">{{
              $t("label.campaign.channel.download.size3")
            }}</a>
          </p>
        </div>
      </div>
      <div style="float: left; text-align: center; width: 340px">
        <a :href="url" target="_blank">
          <img
            :alt="$t('label.campaign.channel.code')"
            width="200px;"
            :src="qrcodeImg"
          />
        </a>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import * as FormMarkObjApi from "./api.js";

export default {
  name: "qrcode",
  components: {},
  props: {
    //相关列表每条数据id
    qrCodeId: {
      type: String,
      default: "",
    },
    //相关列表每条数据name
    qrCodeName: {
      type: String,
      default: "",
    },
    //记录名
    bigTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      qrcodeFlg: false,
      download500: "",
      download300: "",
      channel: "",
      download1000: "",
      campaignname: "",
      url: "",
      qrcodeImg: "",
    };
  },
  methods: {
    //二维码
    init() {
      // 
      FormMarkObjApi.getShareInfo({ id: this.qrCodeId }).then((res) => {
        if (res.result) {
          this.download500 = res.data.download500;
          this.download300 = res.data.download300;
          this.download1000 = res.data.download1000;
          this.url = res.data.url;
          this.qrcodeImg = res.data.qrcodeUrl;
          this.qrcodeFlg = true;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    // 关闭弹窗
    handleClose() {
      this.qrcodeFlg = false;
    },
  },
};
</script>
<style scoped>
.content {
  font-size: 14px;
  padding-left: 20px;
}
.download {
  margin-left: 40px !important;
}
.wrapper {
  padding-left: 10px;
  padding-top: 10px;
  word-break: break-all;
  font-family: "Microsoft Yahei";
  float: left;
  width: 400px;
}
</style>

<template>
  <div>
    <div class="opportunities">
      <el-dialog :visible.sync="dialogVisible" width="1035px">
        <template slot="title">
          <!-- 查找类似业务机会 -->
          <span class="title_one">{{
            $t("label.h5.similar.opportunity.lookup")
          }}</span>
          <br />
          <span class="title_two">
            <!-- 结果为已结束且赢得客户的业务机会中，包含与当前业务机会有一个或多个相同字段的记录。 -->
            {{ $t("vue_label_opportunity_business_opportunity") }}
          </span>
        </template>
        <div class="content">
          <span
            style="
              font-size: 14px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: bold;
              margin-right: 20px;
            "
          >
            <!-- 选择匹配条件 -->
            {{ $t("lable.outlook.detail.match") }}
          </span>
          <span class="button" @click="selectAll">
            <!-- 全选 -->
            {{ $t("label.selectall") }}
          </span>
          <span class="button" @click="cancel">
            <!-- 删除 -->
            {{ $t("component_setup_tabs_label_delete") }}
          </span>
        </div>
        <div class="choice">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in JsonList"
              :key="index"
              :label="item.valueList[0].id"
            >
              <span>{{ item.name }}:</span>
              <span v-for="items in item.valueList" :key="items.id"
                >{{ items.name }}({{ items.idList.length }})</span
              >
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="exhibition">
          <div class="exhibition_header">
            <!-- 结束日期 -->
            <span class="exhibition_data">
              {{ $t("label.quickbooks.searchlist.enddate") }}:</span
            >
            <!-- 请选择 -->
            <el-select
              v-model="value"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
            >
              <el-option
                v-for="item in cities"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div>
          <el-table
            :data="jsonContent"
            style="width: 100%; margin-bottom: 20px; text-align: center"
            row-key="id"
            default-expand-all
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            v-loadmore="loadMore"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <template v-for="item in jsonTitle" :label="item.name">
              <slot :name="item.schemefieldname" :item="item">
                <el-table-column
                  :prop="item.schemefieldName"
                  :label="item.labelname"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row[item.schemefieldname] }}</span>
                  </template>
                </el-table-column>
              </slot>
            </template>
            <!-- 相关性 -->
            <el-table-column
              :label="$t('label.h5.similar.opportunity.correlation')"
            >
              <template slot-scope="scope">
                <el-progress
                  :percentage="scope.row.xgx"
                  :show-text="false"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消 -->
          <el-button @click="clonse">
            {{ $t("button_partner_cancel") }}</el-button
          >
          <el-button type="primary" @click="add">
            {{ $t("vue_label_opportunity_related_list") }}
            <!-- 添加到相关列表 -->
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  getSimilarOpportunityGetSimilarOpportunity,
  getSimilarOpportunitygetSimilarOppConditions,
  getSimilarOpportunitySaveBookmarksOppDetails,
} from "./api.js";
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      checkList: [],
      cities: [
        {
          value: "3",
          label: this.$i18n.t("label.h5.similar.opportunity.last.months3"),
          // 最近3个月
        },
        {
          value: "6",
          label: this.$i18n.t("label.h5.similar.opportunity.last.months6"),
          // 最近3-6个月
        },
        {
          value: "9",
          label: this.$i18n.t("label.h5.similar.opportunity.last.months9"),
          // 最近6-9个月
        },
        {
          value: "12",
          label: this.$i18n.t("label.h5.similar.opportunity.last.months12"),
          // 最近9-12个月
        },
      ],
      value: "3",
      svNum: 20,
      svPage: 1,
      JsonList: [],
      listId: [],
      isIndeterminate: false,
      jsonTitle: [],
      jsonContent: [],
      loading: false,
      arr: [],
      obj: {},
    };
  },

  methods: {
    async getSimilarOpportunityGetSimilarOpportunitys() {
      this.loading = true;
      let str = "";
      this.checkList.forEach((item) => {
        str += item + ";";
      });
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }
      let params = {
        oppId: this.$route.params.id,
        svNum: this.svNum,
        svPage: this.svPage,
        checkCondition: str,
        checkDate: this.value,
      };
      let res = await getSimilarOpportunityGetSimilarOpportunity(params);
      if (res.result) {
        let num = 0;
        let json = JSON.parse(res.data.json);
        this.JsonList.forEach((items) => {
          items.valueList.forEach((itemes) => {
            json.jsonContent.forEach((item) => {
              if (itemes.idList.length !== 0) {
                itemes.idList.forEach((itemId) => {
                  if (itemId.id === item.id) {
                    item.xgx =
                      item.xgx !== 0 &&
                      item.xgx !== null &&
                      item.xgx !== undefined
                        ? item.xgx + 17
                        : num + 17;
                  }
                });
              }
              // }
            });
          });
        });
        if (this.isIndeterminate === true) {
          this.jsonContent = this.jsonContent.concat(json.jsonContent);
        } else {
          this.jsonTitle = json.jsonTitle;
          this.jsonContent = json.jsonContent;
        }
      }
      this.loading = false;
    },
    async getSimilarOpportunitygetSimilarOppConditiones() {
      this.listId = [];
      this.JsonList = [];
      let params = {
        oppId: this.$route.params.id,
        checkDate: this.value,
      };
      let res = await getSimilarOpportunitygetSimilarOppConditions(params);
      if (res.result) {
        let json = JSON.parse(res.data.json);
        this.JsonList = json.jsonList;
        json.jsonList.forEach((item) => {
          item.valueList.forEach((items) => {
            this.listId.push(items.id);
          });
        });
      }
    },
    selectAll() {
      this.checkList = this.listId;
    },
    cancel() {
      this.checkList = [];
      this.isIndeterminate = false;
    },
    handleSelectionChange(val) {
      this.arr = val;
    },
    // 滚动事件
    loadMore() {
      let that = this;
      // let dom = document.querySelector('.el-table__body-wrapper')
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 0) {
          //等于0证明已经到底，可以请求接口
          that.fnThrottle(that.append, 500, 1000)();
        }
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 表格滚动懒加载
    append() {
      this.isIndeterminate = true;
      this.svPage = this.svPage + 1;
      this.getSimilarOpportunityGetSimilarOpportunitys();
    },
    dialogVisibles() {
      this.dialogVisible = !this.dialogVisible;
      if (this.dialogVisible) {
        this.getSimilarOpportunitygetSimilarOppConditiones();
        this.getSimilarOpportunityGetSimilarOpportunitys();
      }
    },
    each(val) {
      this.obj = val;
    },
    async add() {
      let str = "";
      this.arr.forEach((item) => {
        str += item.id + ";";
      });
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }
      let params = {
        id: this.$route.params.id,
        ids: str,
      };
      let res = await getSimilarOpportunitySaveBookmarksOppDetails(params);
      if (res.result) {
        this.getSimilarOpportunityGetSimilarOpportunitys();
        this.getSimilarOpportunitygetSimilarOppConditiones();
        this.$parent.getDetailRelatedItems(false, this.obj);
      }
    },
    clonse() {
      this.dialogVisible = false;
      //  this.$parent.init()
    },
  },
  watch: {
    checkList(val) {
      this.svPage = 1;
      this.svNum = 20;
      this.isIndeterminate = false;
      this.getSimilarOpportunityGetSimilarOpportunitys();
    },
    value(val) {
      this.svPage = 1;
      this.svNum = 20;
      this.checkList = [];
      this.getSimilarOpportunitygetSimilarOppConditiones();
      this.getSimilarOpportunityGetSimilarOpportunitys();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .opportunities {
  .el-dialog__wrapper {
    .el-dialog {
      height: 710px;
      .el-dialog__header {
        .title_one {
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-size: 14px;
          font-weight: bold;
        }
        .title_two {
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
      }
      .el-dialog__body {
        padding: 16px 36px 0px 36px;
        height: 573px;

        .content {
          .button {
            font-size: 12px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #006dcc;
            margin-right: 20px;
            cursor: pointer;
          }
        }

        ::v-deep .choice {
          margin-top: -4px;
          .el-checkbox-group {
            margin-top: 10px;
            .el-checkbox {
              width: 280px !important;
              margin-top: 6px !important;
              position: relative;
              .el-checkbox__label {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: absolute;
                top: 2px;
              }
            }
          }
        }

        .exhibition {
          margin-top: 20px;
          margin-bottom: 12px;
          .exhibition_header {
            .exhibition_data {
              font-size: 12px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: bold;
              margin-right: 12px;
            }
            .el-select {
              width: 135px;
              height: 24px;
              .el-input {
                height: 100%;
                .el-input__inner {
                  height: 100%;
                  font-size: 12px;
                  padding: 0 8px;
                }
                .el-input__suffix {
                  .el-input__suffix-inner {
                    .el-input__icon {
                      line-height: 24px !important;
                    }
                  }
                }
              }
            }
          }
        }

        .el-table {
          margin-bottom: 0px !important;

          .el-table__body-wrapper {
            width: 100.5%;
            height: 350px;
            overflow-y: auto;
          }
        }
      }
      .el-dialog__footer {
        .el-button {
          border-radius: 3px !important;
        }
      }
    }
  }
}

::v-deep .has-gutter {
  tr {
    th {
      background: #f5f5f5;
      font-size: 12px;
      color: #3e3e3c;
      font-family: SourceHanSansCN-Regular;
      padding: 9px 0 !important;
    }
  }
}

::v-deep .el-table__row {
  font-family: SourceHanSansCN-Regular;
  font-size: 12px;
  color: #333333;
}
</style>

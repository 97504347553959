<template>
  <div class="invitationCode">
    <!-- 邀约码 -->
    <el-dialog
      :visible.sync="invitationCodeDialog"
      top="4%"
      :title="bigTitle"
      width="80%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :before-close="closeInvitationCodeDialog"
    >
      <!-- 选择需要发送邀约码的员工 -->
      <p class="remarks_box">{{ $t("c84") }}</p>
      <div style="margin-bottom: 10px">
        <span style="font-size: 14px; margin-right: 10px">
          <!-- 视图筛选 -->
          {{ $t("vue_label_norm_viewfilter") }}
        </span>
        <!-- 请选择 -->
        <el-select
          v-model="viewId"
          :placeholder="$t('label_tabpage_pleaseSelectz')"
        >
          <el-option
            v-for="item in views"
            :key="item.label"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 列表 -->
      <table-panel
        class="text-center"
        ref="tablePanel"
        :border="true"
        :serialDrop="true"
        :table-height="tableHeight"
        :show-index="true"
        :checked="true"
        :show-table-head-tool="false"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :noDataTip="noDataTip"
        :pict-loading="pictLoading"
        :use-hyperlinks="false"
        @locked="locked"
        @append="append"
      >
      </table-panel>
      <div style="font-size: 14px; margin-top: 8px">
        <!-- 已选择多少个邀约员工 -->
        {{
          $t("c85", {
            member:
              ($refs.tablePanel && $refs.tablePanel.checkedList.length) || 0,
          })
        }}
      </div>
      <div style="margin-top: 10px; text-align: right" slot="footer">
        <!-- 取消 -->
        <el-button size="mini" @click="closeInvitationCodeDialog">
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="next">
          <!-- 下一步 -->
          {{ $t("label.nextstep") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 下一步 -->
    <!-- 添加到市场活动 -->
    <sendMail
      ref="sendMail"
      :ids="ids"
      :id="id"
      @closeSendMail="closeSendMail"
    />
  </div>
</template>

<script>
import { TablePanel } from "@/components/index";
import sendMail from "./sendMail.vue";
import * as CommonObjApi from "./api.js";
import * as FormMarkObjApi from "./api.js";

export default {
  props: {
    invitationCode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    TablePanel,
    sendMail,
  },
  data() {
    return {
      campaignId: this.$route.params.id,
      viewId: "",
      views: [],
      status: "",
      statusList: [],
      tableHeight: 400,
      tableAttr: null,
      pageObj: {
        dataList: [],
      },
      noDataTip: false,
      pictLoading: true,
      invitationCodeDialog: this.invitationCode,
      marketingActivitiesName: "",
      pageNum: 1,
      pageSize: 20,
      sortField: "", // 排序字段
      sortFieldName: "",
      sortDir: "asc", // 排序方式
      searchKeyWord: "", // 搜索关键字
      conditionValues: "",
      tagIds: [], // 搜索标签
      isAllTag: "", // 是否同时满足所有标签
      isallsearch: "false",
      myself: "false",
      scrollId: "false",
      ids: [],
      type: "",
      imgSrc: require("@/assets/images/details/schd.png"),
      bigTitle: this.$i18n.t("c86"), //市场活动：编辑员工邀约
    };
  },
  created() {},
  methods: {
    getViewList() {
      FormMarkObjApi.GetViewList({ objId: "user" })
        .then((res) => {
          res.data.forEach((val) => {
            if (val.isdefault === "1") {
              this.viewId = val.id;
            }
          });
          this.views = res.data;
          this.pageNum = 1;
          this.pageObj.dataList = [];
          this.$refs.tablePanel.checkedList = [];
          this.getViewListHeader();
          this.getViewListData();
        })
        .catch(() => {});
    },
    // 获取视图列表表头
    getViewListHeader() {
      this.pictLoading = true;
      this.tableAttr = [];

      CommonObjApi.getViewListHeader({
        conditionValues: "",
        isAllTag: "",
        obj: "005",
        searchKeyWord: "",
        tagIds: "",
        viewId: this.viewId,
      }).then((res) => {
        this.tableAttr = res.data.headLabel;
        this.tableAttr.forEach((item, idx) => {
          this.$set(this.tableAttr[idx], "edit", false);

          // 名称、查找、主详、查找多选均可跳转
          if (
            item.schemefieldName === "name" ||
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR"
          ) {
            this.$set(this.tableAttr[idx], "click", "detail");
          }

          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
          this.$set(this.tableAttr[idx], "modify", false);
        });

        this.pictLoading = false;
        this.$refs.tablePanel &&
          this.$refs.tablePanel.changeTableAttr(this.tableAttr);
      });
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getViewListData();
      }
    },
    // 获取视图数据
    getViewListData() {
      /**
       * 参数说明
       * 参数名	必选	类型	说明
       * obj	是	string	对象前缀
       * viewId	是	string	视图Id
       * pageNum	是	string	页数
       * pageSize	是	string	每页数量
       * sortField	是	string	排序字段apiname
       * sortDir	是	string	排序类型(desc[降序]/asc[升序])
       * searchKeyWord	是	string	搜索关键字
       * conditionValues	是	string	高级搜索条件
       * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
       * recordnums	是	string	每页查询默认数目
       * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
       * isAllTag	否	string	是否同时满足所有选中标签（true/false）
       * campaignid	否	string	市场活动成员是否只查询现有成员
       * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
       * myself	否	string	全局搜索是否只查询所有人是自己的记录
       * scrollId	否	string	全文检索查询返回值，下一页查询回传
       * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
       */

      this.noDataTip = false;
      this.pictLoading = true;
      if (this.pageNum === 1) {
        this.pageObj.dataList = [];
      }
      this.getViewListDataParams = {
        obj: "005",
        viewId: this.viewId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDir: this.sortField === "" ? "" : this.sortDir,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
        ischangenum: "false",
        recordnums: this.pageSize,
        tagIds: this.tagIds.toString(),
        isAllTag: this.isAllTag,
        campaignid: "false",
        isallsearch: this.isallsearch,
        myself: this.myself,
        scrollId: this.scrollId,
        type: "",
      };
      CommonObjApi.getViewListData(this.getViewListDataParams).then((res) => {
        this.totalSize = res.data.totalSize;

        if (res.data.list.length === 0 && this.pageNum === 1) {
          this.pageObj.dataList = [];
        } else if (this.pageObj.dataList.length === 0 || this.pageNum === 1) {
          this.pageObj.dataList = res.data.list;
          this.pageNum++;
        } else {
          let checkedList = this.$refs.tablePanel.getCheckedList();
          this.pageObj.dataList = [...this.pageObj.dataList, ...res.data.list];
          this.pageObj.dataList.forEach((element) => {
            checkedList.forEach((item) => {
              if (item.id === element.id) {
                element.checked = true;
              }
            });
          });

          this.pageNum++;
          this.$refs.tablePanel.dataList = this.pageObj.dataList;
        }
        // 判断是否有更多数据
        if (res.data.haveMore === "false") {
          this.noDataTip = true;
        }

        this.pictLoading = false;
        this.lastRefresh = new Date();
        this.pageObj.totalSize = res.data.totalSize;
      });
    },
    // 下一步
    next() {
      if (this.$refs.tablePanel.checkedList.length > 0) {
        this.ids = [];
        this.type = "keep";

        let checkedList = this.$refs.tablePanel.checkedList;
        checkedList.forEach((checked) => {
          this.ids.push(checked.id);
        });
        this.getSelectValue();
      } else {
        this.$message.warning(
          this.$i18n.t("vue_label_SMS_selectadd", { name: name })
        );
      }
    },
    closeSendMail() {
      this.invitationCodeDialog = false;
      this.$emit("closeSendMail");
    },
    getSelectValue() {
      this.$refs.sendMail.sendVisible = true;
    },
    // 锁定
    locked(field) {
      let fiexdList = this.tableAttr.filter((item) => {
        return item.fixed === true;
      });

      if (fiexdList.length > 2) {
        // Message.warning("最多只能固定三列");
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.fixed = false;
            item.locked = false;
          }
        });
      } else {
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.fixed = !item.fixed;
          }
        });
      }

      this.$forceUpdate();
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    closeInvitationCodeDialog() {
      this.$emit("closeInvitationCodeDialog");
    },
  },
  watch: {
    viewId(nval, oval) {
      if (this.invitationCode && oval !== "") {
        this.pageNum = 1;
        this.pageObj.dataList = [];
        this.$refs.tablePanel.checkedList = [];
        this.getViewListHeader();
        this.getViewListData();
      }
    },
    invitationCode(nval) {
      this.invitationCodeDialog = nval;
      this.getViewList();
    },
  },
};
</script>

<style lang="scss" scoped>
.remarks_box {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  background: #eee;
  padding: 0 5px;
  margin-bottom: 10px;
}
.red {
  color: red;
}

.labelBox {
  font-size: 14px;
  color: #666;
}

.marketingActivitiesName {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border: 1px solid #e3e2e2;
  border-radius: 3px;
}

::v-deep .el-tabs__item {
  font-size: 15px;
}

::v-deep .el-select .el-input__inner {
  height: 30px;
  line-height: 30px;
}

::v-deep .el-input__icon {
  line-height: 30px;
}
</style>
